import { AddSessionLogType, CreateSessionLogPayload, EditableStudentsType } from 'src/redux/sessionLogsRedux/types';
import { toApiDateOrNull, toApiTimeOrNull } from 'src/utils/momentUtils';
import { scroller } from 'react-scroll';
import * as yup from 'yup';
import moment from 'moment';
import { LOCAL_STORAGE_SUBMIT_CLONE } from 'src/services/submitCloneNewSL';

export const CONSTANT_ADD_LOG = {
  WALKIN: 'WALKIN',
  IN_PERSON: 'inperson',
  CRISIS: 'CRISIS',
  CONSULTATION: 'CONSUL',
  CONSULTATION_PARENT: 'CONSUL_PAR',
  OBSERVATION: 'OBS',
};
export const INITIATED_BY = {
  SELF: 'SELF',
  TEACHER: 'TEACHER',
  ADMIN: 'ADMIN',
  PARENT: 'PARENT',
  BHS: 'BHS',
  OTHER: 'OTHER',
};

export const isOtherInitiators = (value: string) => {
  return (value === INITIATED_BY.TEACHER || value === INITIATED_BY.ADMIN || value === INITIATED_BY.PARENT || value === INITIATED_BY.BHS || value === INITIATED_BY.OTHER);
};

export const isCrisisOrConsultation = (serviceDelivered: string, sessionType: string) => {
  return (sessionType === CONSTANT_ADD_LOG.CRISIS || serviceDelivered === CONSTANT_ADD_LOG.CONSULTATION || serviceDelivered === CONSTANT_ADD_LOG.CONSULTATION_PARENT);
};

export const isObservation = (value: string) => {
  return (value === CONSTANT_ADD_LOG.OBSERVATION);
};

export const scrollToTop = (nameElement: string) => {
  scroller.scrollTo(`${nameElement}`, {
    smooth: true,
    duration: 500,
    offset: -90,
  });
};

export const formValuesToPayload = (
  {
    student,
    hStudentId,
    studentNotAvailable,
    reasonStudentNotAvailable,
    provider,
    providerHUserId,
    providerId,
    credentialName,
    primaryMethodOfContact,
    sessionType,
    referredBy,
    referredOtherDescription,
    sessionDate,
    startTime,
    endTime,
    sessionLength,
    groupSize,
    location,
    locationDetails,
    deliveryMode,
    directNonDirect,
    roomName,
    targets,
    concernTreatments,
    focusAreaAsPlan,
    outcomes,
    practiceElements,
    progressMeasures,
    notes,
    licenseAttestUserId,
    licenseAttestName,
    licenseAttestDate,
    comments,
    supportTypeId,
  }: AddSessionLogType,
  students?: EditableStudentsType[],
  studentId?: string | number,
): CreateSessionLogPayload => {
  // loosely compare studentId because studentId can be string or number
  /* eslint-disable-next-line */
  const selectedStudent = students?.find(s => s.studentId == studentId);
  const studentSupportIsService = selectedStudent?.supportType === 'service' ? true : false;

  return {
    studentId: selectedStudent ? selectedStudent.studentId : student.studentId,
    hStudentId: hStudentId,
    providerUserId: provider.userId,
    providerHUserId: providerHUserId,
    supportType: selectedStudent ? selectedStudent.supportType : student.supportType,
    supportTypeId: supportTypeId && studentSupportIsService ? supportTypeId : null,
    supportId: selectedStudent ? selectedStudent.supportId : student.supportId,
    providerId:  selectedStudent ? selectedStudent.providerId : student.providerId,
    sessionDate: toApiDateOrNull(sessionDate),
    sessionTime: studentNotAvailable ? '' : toApiTimeOrNull(startTime),
    sessionMinutes: studentNotAvailable ? null : +sessionLength,
    studentAbsentFlag: studentNotAvailable ? 1 : 0,
    studentAbsentReason: studentNotAvailable ? reasonStudentNotAvailable : '',
    contactType: studentNotAvailable ? '' : primaryMethodOfContact,
    sessionType: studentNotAvailable ? '' : sessionType,
    referredBy: studentNotAvailable ? '' : referredBy,
    referredByOther: studentNotAvailable ? '' : referredOtherDescription !== '' ? referredOtherDescription : null,
    groupSize: studentNotAvailable ? null : groupSize ? +groupSize : null,
    locationId: studentNotAvailable ? null : location ? location.locationId : null,
    locationDetails: studentNotAvailable ? '' : locationDetails ? locationDetails : '',
    deliveryMode: studentNotAvailable ? '' : deliveryMode ? deliveryMode : null,
    directNonDirect: studentNotAvailable ? '' : directNonDirect ? directNonDirect : null,
    roomName: studentNotAvailable ? null : roomName ? roomName : null,
    comments: comments,
    medicaidFlag: 1,
    medicaidMinutes: sessionLength ? +sessionLength : null,
    licenseAttestUserId: licenseAttestUserId,
    licenseAttestName: licenseAttestName ? licenseAttestName : null,
    licenseAttestDate: licenseAttestDate,
    logs: [
      {
        comment: 'Create Log',
      },
    ],
    TPO: studentNotAvailable
      ? [
          {
            targetId: null,
            targetOther: '',
            target: '',
            concernTreatmentId: null,
            concernTreatmentOther: '',
            concernTreatment: '',
            focusAreaAsPlanId: null,
            focusAreaAsPlan: '',
            practiceId: null,
            practiceOther: '',
            practiceElement: '',
            outcomeId: null,
            notes: '',
          },
        ]
      : Array.from({ length: targets.length }, (_, i) => ({
          targetId: targets[i] !== null ? parseInt(targets[i].id) : null,
          target: targets[i] !== null ? targets[i].target : null,
          targetOther: targets[i] !== null ? targets[i].description : null,
          concernTreatmentId: concernTreatments[i] !== null ? parseInt(concernTreatments[i].id) : null,
          concernTreatment: concernTreatments[i] !== null ? concernTreatments[i].concernTreatment : null,
          concernTreatmentOther: concernTreatments[i] !== null ? concernTreatments[i].description : null,
          focusAreaAsPlanId: focusAreaAsPlan[i] !== null ? parseInt(focusAreaAsPlan[i].id) : null,
          focusAreaAsPlan: focusAreaAsPlan[i] !== null ? focusAreaAsPlan[i].focusAreaAsPlan : null,
          practiceId: practiceElements[i] !== null ? parseInt(practiceElements[i].id) : null,
          practiceOther: practiceElements[i] !== null ? practiceElements[i].description : null,
          practiceElement: practiceElements[i] !== null ? practiceElements[i].practiceElement : null,
          outcomeId: outcomes[i] !== null ? outcomes[i] : null,
          notes: notes[i] !== null ? notes[i] : '',
        })),
        BIMAS: studentNotAvailable
        ? [
            {
              id: null,
              measureCategoryCode: '',
              measureId: null,
              measureOther: '',
              score: '',
            },
          ]
        : Array.from({ length: progressMeasures.length }, (_, i) => ({
            id: null,
            measureCategoryCode: progressMeasures[i] !== null ? progressMeasures[i].measureCategoryCode : null,
            measureId: progressMeasures[i] !== null && progressMeasures[i].measureCategoryCode !== 'other' ? progressMeasures[i].measureId : null,
            measureOther: progressMeasures[i] !== null && progressMeasures[i].measureCategoryCode === 'other' ? progressMeasures[i].measureOther : null,
            score: progressMeasures[i] !== null ? progressMeasures[i].score : '',
          })),
  };
};

export const requiredError = 'Required';

export const AddSessionLogFormSchema = yup.object().shape({
  mode: yup.string(),
  student: yup.object(),
  //licenseAttestUserId: yup.number().typeError('Provider Signature is Required'),
  studentNotAvailable: yup.boolean(),
  reasonStudentNotAvailable: yup
    .string()
    .nullable()
    .when('studentNotAvailable', {
      is: true,
      then: yup.string().typeError(`Reason is required.`).required(`Reason is required.`),
    }),
  provider: yup.object().required(),
  providerHUserId: yup.number().required(),
  credentialName: yup.string().nullable(),
  // .string()
  // .nullable()
  // .when('studentNotAvailable', {
  //   is: false,
  //   then: yup.string().typeError(`Delivery Mode is required.`).required(`Delivery Mode is required.`),
  // }),
  primaryMethodOfContact: yup
    .string()
    .nullable()
    .when('studentNotAvailable', {
      is: false,
      then: yup
        .string()
        .typeError(`Service Delivered is Required.`)
        .required(`Service Delivered is required.`),
    }),
    sessionType: yup
    .string()
    .nullable()
    .when('studentNotAvailable', {
      is: false,
      then: yup
        .string()
        .typeError(`Session Type is required.`)
        .required(`Session Type is required.`),
    }),
  referredBy: yup
    .string()
    .nullable()
    .when('sessionType', {
      is: CONSTANT_ADD_LOG.WALKIN,
      then: yup.string().typeError(`Initiated by is required.`).required(`Initiated by is required.`),
    }),
  referredOtherDescription: yup
    .string()
    .nullable()
    .when('referredBy', {
      is: (val) => isOtherInitiators(val),
      then: yup
        .string()
        .nullable()
        .typeError(`Initiator Name is required.`)
        .required(`Initiator Name is required.`),
    }),
  sessionDate: yup
    .date().typeError(`Please enter a valid date (MM/DD/YYYY)`).required(`Session Date is required.`)
    .test({
      name: 'sessionDateMinMax',
      exclusive: false,
      params: { },
      message: 'Session Must Occur between Start & End Date',
      test: function (value) {
        //Get Max Date
        var maxDate;
        var curr = moment();
        var projDate = moment(localStorage.getItem('ProjectedEndDate'));
        // If end date exists
        if (projDate.isValid()) {
          if(projDate.isAfter(curr)) {
            maxDate = curr;
          }
          else if(projDate.isSameOrBefore(curr)) {
            maxDate = curr;
          }
          else {
            maxDate = projDate;
          }
        }
        // unscheduled student: max end date for session is current date
        else {
          maxDate = curr;
        }

        //Get Minimum Date
        var minDate;
        var currMinusYear = moment().subtract(1, 'years');
        const projectedDate = moment(localStorage.getItem('ProjectedStartDate'));

        // end date will equal whatever added session date was in this case -- needs diff logic
        if ( localStorage.getItem(LOCAL_STORAGE_SUBMIT_CLONE) === 'true' ) {
        if (projDate.isValid()) {
          if(projDate.isAfter(currMinusYear)) {
            minDate = projDate;
          }
          else {
            minDate = currMinusYear;
          }
        }
        else minDate = currMinusYear;
      }
        if (projectedDate.isValid()) {
          if(projectedDate.isAfter(currMinusYear)) {
            minDate = projectedDate;
          }
        else {
          minDate = currMinusYear;
        }
      }
        // unscheduled student: max start date for session is current date minus 1 year
        else {
          minDate = currMinusYear;
        }

        var pickedDate = moment(value);
        minDate = minDate.subtract(1, 'days')

        if (pickedDate.isAfter(curr)) {
          return this.createError({
            message: `Session Date can not be in the future`,
            path: 'sessionDate', // Fieldname
          })
        }
        else return !pickedDate.isBetween(minDate, maxDate) ? this.createError({
          message: `Session Date must be on or within the (Projected) Start Date and (Projected) End Date`,
          path: 'sessionDate', // Fieldname
        })
        : true;
        },
      }),
  startTime: yup
    .date()
    .nullable()
    .when('studentNotAvailable', {
      is: false,
      then: yup
        .date()
        .typeError(`Please enter a valid time (hh:mm  tt)`)
        .required(`Please enter a valid time (hh:mm  tt)`),
    }),
  endTime: yup
    .date()
    .nullable()
    .when('studentNotAvailable', {
      is: false,
      then: yup
        .date()
        .min(yup.ref('startTime'), 'End Time must be later than Start Time.')
        .typeError(`Please enter a valid time (hh:mm  tt)`)
        .required(`Please enter a valid time (hh:mm  tt)`),
    }),
  sessionLength: yup
    .number()
    .nullable()
    .when('studentNotAvailable', {
      is: false,
      then: yup
        .number()
        .min(1, 'Session Length must be greater than 0.')
        .typeError(`Session Length is required.`)
        .required(`Session Length is required.`)
        .test({
          name: 'sessionLengthMax',
          exclusive: false,
          params: { },
          message: 'Session must occur on a single day',
          test: function (value) {
            var length = moment(this.parent.startTime).endOf('day').diff(this.parent.startTime, 'minutes')
              return value > length ? this.createError({
                message: `Session length must be less than ${length + 1} mins`,
                path: 'sessionLength', // Fieldname
              })
              : true;
              },
        }),
    }),
  groupSize: yup.number().nullable().min(2, 'Group Size must be greater than 1.').typeError(`Group Size is required.`),
  location: yup
    .object()
    .nullable()
    .when('studentNotAvailable', {
      is: false,
      then: yup.object().typeError(`Location is required.`).required(`Location is required.`),
    }),
  locationDetails: yup.string().max(50, `Max Location Details is 50 characters`).nullable(),
  deliveryMode: yup
    .string()
    .nullable()
    .when('studentNotAvailable', {
      is: false,
      then: yup.string().typeError(`Delivery Mode is required.`).required(`Delivery Mode is required.`),
    }),
  roomName: yup.string().max(30, `Max Classroom Number is 30 characters`).nullable(),
  targets: yup
    .mixed()
    .nullable()
    .when('studentNotAvailable', {
      is: false,
      then: yup.array(
        yup
          .object({
            id: yup.number().required(`Targeted Concern is required.`),
            needDescription: yup.boolean(),
            description: yup.string().max(50, `Max Description is 50 characters`).nullable().optional(),
          })
          .nullable(),
      ),
    }),
  concernTreatments: yup
    .mixed()
    .nullable()
    .when('studentNotAvailable', {
      is: false,
      then: yup.array(
        yup
          .object({
            id: yup.number().nullable(),
            needDescription: yup.boolean(),
            description: yup.string().max(50, `Max Description is 50 characters`).nullable().optional(),
          })
          .nullable(),
      ),
    }),
  practiceElements: yup
    .mixed()
    .nullable()
    .when('studentNotAvailable', {
      is: false,
      then: yup.array(
        yup
          .object({
            id: yup.number().nullable(),
            needDescription: yup.boolean(),
            description: yup.string().max(50, `Max Description is 50 characters`).nullable().optional(),
          })
          .nullable(),
      ),
    }),
    progressMeasures: yup
    .mixed()
    .nullable()
    .when('studentNotAvailable', {
      is: false,
      then: yup.array(
        yup
          .object({
            measureOther: yup.string().max(30, `Max Measure Description is 30 characters.`).nullable().optional(),
            score: yup.string().max(25, `Max Score Description is 25 characters.`).nullable().optional(),
          })
          .nullable(),
      ),
    }),
  focusAreaAsPlan: yup
    .mixed()
    .nullable()
    .when('studentNotAvailable', {
      is: false,
      then: yup.object({
        id: yup.number().nullable(),
        focusAreaAsPlan: yup.string().typeError(`Focus Area in Plan is required.`).required(`Focus Area in Plan is required.`),
      })
      .nullable(),
    }),
  notes: yup.array().nullable(),
  comments: yup.string().nullable(),
});
