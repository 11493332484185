import { Button, Checkbox, FormControlLabel, FormHelperText, Grid, IconButton, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { ArrayHelpers, Field, FieldArray, FieldProps, Form, Formik, FormikConfig, FormikProps } from 'formik';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { connect } from 'react-redux';
import { MuiDatePickerFormikField, MuiDatePickerMomentUtilsProvider } from 'src/components/common/KeyboardDatePicker';
import SearchDropdownBox from 'src/components/SearchDropdownBox';
import { IRootState } from 'src/redux/rootReducer';
import { ServiceLocation } from 'src/redux/schedulerRedux/types';
import './styles.scss';
import {
  AddSessionLogType,
  OptionSelectSLType,
  OutComeType,
  PracticeElementType,
  TargetAreaType,
  BehaviorCategoryType,
  BehaviorMeasureType,
  sessionOptionsSelectSLType,
  ConcernTreatmentType,
} from 'src/redux/sessionLogsRedux/types';
import { MuiTimePickerFormikField } from 'src/components/common/MuiTimePickers';
import { searchLocationAddSLAsync, setLicenseAttestFlagSL } from 'src/redux/sessionLogsRedux/actions';
import { getLocationOptionLabel } from 'src/containers/SchedulerContainers/helper';
import moment, { Moment } from 'moment';
import { isEmpty as _isEmpty } from 'lodash';
import React from 'react';
import { CONSTANT_ADD_LOG, isCrisisOrConsultation, isObservation, isOtherInitiators } from '../../AddSessionLog/helper';
import { Autocomplete } from '@material-ui/lab';
import { getBehaviorMeasureTitle, getConcernTreatmentTitle, getPracticeElementTitle, getProgressMeasureTitle, getTargetTitle, isConcernTreatmentTypeOther, isPracticeTypeOther, isTargetTypeOther } from '../SessionLogFormTemplate';
import { filterDisabledBMOptions, filterDisabledConcernOptions, filterDisabledOptions, filterDisabledOptionSelectSL, filterDisabledPMOCOptions, filterDisabledPMOptions, filterDisabledPracticeOptions, filterOptionsMatchAllKeywords } from 'src/utils';
import { muiResponsive } from 'src/appConfig/muiTheme';
import { isEmpty } from 'src/validations';
import CustomPopperAutocomplete from 'src/components/CustomPopperAutocomplete';
import { LOCAL_STORAGE_SUBMIT_CLONE } from 'src/services/submitCloneNewSL';

const clsPrefix = 'add-session-log-form';
const MAX_TARGETS = 3;
const MAX_PROGRESS_MEASURES = 5;

type Props = FormikProps<AddSessionLogType> &
  ReturnType<typeof mapState> &
  typeof mapDispatch & {
    studentId?: number;
    startDate?: string;
    endDate?: string;
    supportEndDate?: string;
  };

export const getOutcomeLabel = (o: OutComeType) => o.outcome;
let hasFormChanged = false;
let hideProgressMeasures = false;
let providerSigError = (localStorage.getItem('ProviderSignatureError') === 'true') ? true : false;
let groupSizeError = '';

export const AddSessionLogForm = ({
  values,
  searchLocations,
  searchSLLocation,
  clearSearchLocation,
  targets,
  concernTreatments,
  focusAreaAsPlan,
  practices,
  behaviorMeasures,
  progressMeasures,
  outcomes,
  studentId,
  contact,
  sessionTypes,
  deliveryMode,
  directNonDirect,
  referredBy,
  absentReason,
  mode,
  setLicenseAttestFlagSL,
  licenseAttestFlag,
  startDate,
  endDate,
}: Props) => {
  
  const isMobile = useMediaQuery(muiResponsive.MOBILE);
  const editLicenseAttestFullName = values.licenseAttestName;
  const editLicenseAttestDate = values.licenseAttestDate;
  const isContactWalkin = values.sessionType === CONSTANT_ADD_LOG.WALKIN;
  const isReferredByOther = isOtherInitiators(values.referredBy);
  const isDeliveryInPerson = values.deliveryMode === CONSTANT_ADD_LOG.IN_PERSON;
  const isEdit = mode === 'edit';
  const isClone = mode === 'clone';
  const isCrisisOrConsult = isCrisisOrConsultation(values.primaryMethodOfContact, values.sessionType);
  const isObservationService = isObservation(values.primaryMethodOfContact);
  
  let providerChecked = values.licenseAttestUserId ? true : false;

  // Set values based on if clone, add, or create
  React.useEffect(() => {
    if(isClone) {
      setLicenseAttestFlagSL(false);
      providerSigError = true;
      hasFormChanged = false;
    }
    if( (!isClone) && (!isEdit) ){
      setLicenseAttestFlagSL(false);
      hasFormChanged = false;
    }
    if(isEdit) {
      setLicenseAttestFlagSL(true);
      hasFormChanged = false;
      if(values.groupSize === '1') {
        groupSizeError = 'Group Size must be greater than 1';
      }
    }
  }, []);

  React.useEffect(() => {
    values.progressMeasures.forEach((_, index) => {
      if(!values.progressMeasures?.[index]?.measureCategoryCode) {
        if(values.progressMeasures?.[index]?.measureId) { values.progressMeasures[index].measureId = null; }
        if(values.progressMeasures?.[index]?.measureOther) { values.progressMeasures[index].measureOther = ''; }
        if(values.progressMeasures?.[index]?.score) { values.progressMeasures[index].score = ''; }
      }
    });
  }, [ values.progressMeasures ]);

  // Checks PMOC/Service Delivered value and if progress measures exist to determine
  // whether or not the progress measures should be shown
  React.useEffect(() => {
    let progressHasValues = false;
    values.progressMeasures.forEach((_, index) => {
      if(values.progressMeasures?.[index]?.measureCategoryCode) {
        progressHasValues = true;
      }
    });
    if (!progressHasValues && isCrisisOrConsult) hideProgressMeasures = true;
    else if (progressHasValues && isCrisisOrConsult) hideProgressMeasures = false;
    else hideProgressMeasures = false;
  }, [ values.primaryMethodOfContact ]);

  // Changes on Edit 
  React.useEffect(() => {
      if (hasFormChanged) {
        setLicenseAttestFlagSL(false);
        providerSigError = true;
        providerChecked = false;
        values.licenseAttestUserId = null;
        values.licenseAttestDate = null;
        hasFormChanged = false;
      }
      else if (!values.licenseAttestUserId && !providerSigError) {
        providerSigError = (localStorage.getItem('ProviderSignatureError') === 'true') ? true : false;
      }
      else if (values.licenseAttestUserId && providerSigError) {
        providerSigError = false;
      }
  }, [values]);

  // Calc correct max date
  const getSoonestDate = (date: Moment) => {
    var curr = moment();
    var endDate = date.format('L');
    var projDate = moment(localStorage.getItem('ProjectedEndDate'));
    
    // If end date exists
    if (date.isValid()) {
      if(date.isAfter(curr)) { 
        return curr; 
      }
      else if(endDate !== curr.format('L')) {
        return date;
      }
      else return curr;
    }
    // if creating a new log w/ related or TS
    else if (projDate.isValid()) {
      if(projDate.isAfter(curr)) {
        return curr;
      }
      else {
        return projDate;
      }
    }
    // unscheduled student: max end date for session is current date
    else {
      return curr;
    }
  }

  const getCurrentDate = () => {
    const testDate = new Date().toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
    return testDate;
  }

  const formatDate = (val: string) => {
    const formatted = new Date(val).toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
    return formatted;
  }

  // Calc correct min date
  const getStartValue = (date: Moment) => {
    var currMinusYear = moment().subtract(1, 'years');
    var startDate = date.format('L');
    const projDate = moment(localStorage.getItem('ProjectedStartDate'));

    // end date will equal whatever added session date was in this case -- needs diff logic
    if ( localStorage.getItem(LOCAL_STORAGE_SUBMIT_CLONE) === 'true' ) {
      if (projDate.isValid()) {
        if(projDate.isAfter(currMinusYear)) {
          return projDate;
        }
        else {
          return currMinusYear;
        }
      }
      else return currMinusYear;
    }

    // If start date exists: (editing or cloning RS/TS)
    if ( date.isValid()) {
      if(date.isAfter(currMinusYear)) {
        return date;
      }
      else if(startDate !== moment().format('L')){
        return currMinusYear;
      }
      else return currMinusYear;
    }
    // if creating a new log w/ related or TS
    else if (projDate.isValid()) {
      if(projDate.isAfter(currMinusYear)) {
        return projDate;
      }
      else {
        return currMinusYear;
      }
    }
    // unscheduled student: max start date for session is current date minus 1 year
    else {
      return currMinusYear;
    }
  }

  const renderDynamicFields = () => (
    <FieldArray name="targets">
      {({ push: pushTarget, remove: removeTarget }: ArrayHelpers) => (
        <FieldArray name="concernTreatments">
          {({ push: pushConcernTreatments, remove: removeConcernTreatments }: ArrayHelpers) => (
            <FieldArray name="practiceElements">
              {({ push: pushPracticeElement, remove: removePracticeElement }: ArrayHelpers) => (
                <FieldArray name="focusAreaAsPlan">
                  {({ push: pushFocusAreaAsPlan, remove: removeFocusAreaAsPlan }: ArrayHelpers) => (
                    <FieldArray name="outcomes">
                      {({ push: pushOutcomes, remove: removeOutcomes }: ArrayHelpers) => (
                        <FieldArray name="notes">
                          {({ push: pushNotes, remove: removeNotes, replace: replaceNotes }: ArrayHelpers) => {
                            const showDeleteButton =
                              values.targets?.length > 1 ||
                              values.concernTreatments?.length > 1 ||
                              values.focusAreaAsPlan?.length > 1 ||
                              values.practiceElements?.length > 1 ||
                              values.outcomes?.length > 1 ||
                              values.notes?.length > 1;

                            const handleAddTarget = () => {
                              pushTarget(null);
                              pushConcernTreatments(null);
                              pushFocusAreaAsPlan(null);
                              pushPracticeElement(null);
                              pushOutcomes(null);
                              pushNotes('');
                            };

                            const handleRemoveTarget = (index: number) => {
                              removeTarget(index);
                              removeConcernTreatments(index);
                              removeFocusAreaAsPlan(index);
                              removePracticeElement(index);
                              removeOutcomes(index);
                              removeNotes(index);
                            };

                            return (
                              <>
                                {Array.from(
                                  {
                                    length: Math.max(values?.targets?.length || 1),
                                  },
                                  (_, index) => (
                                    <Grid
                                      key={`target-and-practice-and-outcome-and-note-${index}`}
                                      container
                                      item
                                      spacing={3}
                                      xs={12}
                                      lg={12}
                                      className={`${clsPrefix}-dynamic-group`}>
                                      <Grid container item xs={showDeleteButton ? 11 : 12} spacing={2}>
                                        <Grid item container xs={12} md={6}>
                                          <Grid item xs={12}>
                                            <Field name={`targets.${index}.id`}>
                                              {({
                                                field: { name, value },
                                                form: { errors, setFieldValue, setFieldTouched, setFieldError, setErrors },
                                                meta: { touched },
                                              }: FieldProps) => {
                                                return (
                                                  <Autocomplete
                                                    key={`targetId-${value}`}
                                                    options={filterDisabledOptions(targets.data)}
                                                    getOptionLabel={(p) => getTargetTitle(p, !isEdit || !_isEmpty(values.formVersion))}
                                                    disabled={isEdit && !values.formVersion && !!values.targets[index]?.id}
                                                    getOptionDisabled={(options) => {
                                                      if (options.disabledFlag) {
                                                        return true;
                                                      }
                                                    }}
                                                    PopperComponent={CustomPopperAutocomplete}
                                                    renderInput={params => (
                                                      <TextField
                                                        {...params}
                                                        label="Targeted Concern *"
                                                        variant="outlined"
                                                        error={touched && !!errors.targets?.[index]?.id}
                                                        helperText={
                                                          touched && errors.targets?.[index]?.id
                                                            ? errors.targets?.[index]?.id
                                                            : ' '
                                                        }
                                                      />
                                                    )}
                                                    value={targets.data.find(t => t.targetId === value)}
                                                    onChange={(_e, target: TargetAreaType) => {
                                                      hasFormChanged = true;
                                                      setFieldValue(`targets.${index}.id`, target?.targetId);
                                                      setFieldValue(
                                                        `targets.${index}.needDescription`,
                                                        isTargetTypeOther(target),
                                                      );
                                                      setFieldValue(`targets.${index}.description`, !target?.otherFlag ? null : '' );
                                                    }}
                                                    filterOptions={filterOptionsMatchAllKeywords<TargetAreaType>(
                                                      getTargetTitle,
                                                    )}
                                                    disableClearable
                                                    onBlur={() => setFieldTouched(name, true)}
                                                  />
                                                );
                                              }}
                                            </Field>
                                          </Grid>
                                        {values.targets?.[index]?.needDescription && (
                                          <Grid item xs={12} className={`${clsPrefix}-dynamic-group-briefDescription`}>
                                              <Field name={`targets.${index}.description`}>
                                                {({ field, form, meta }: FieldProps) => {
                                                  return (
                                                    <TextField
                                                      label="Other Targeted Concern Description *"
                                                      variant="outlined"
                                                      error={meta.touched && !!meta.error}
                                                      helperText={meta.touched && meta.error ? meta.error : ' '}
                                                      fullWidth
                                                      onKeyDown={() => {
                                                        hasFormChanged = true;
                                                      }}
                                                      onBlur={() => form.setFieldTouched(field.name, true)}
                                                      inputProps={{
                                                        autoComplete: 'off',
                                                      }}
                                                      {...field}
                                                    />
                                                  );
                                                }}
                                              </Field>
                                          </Grid>
                                        )}

                                        </Grid>
                                        <Grid item container xs={12} md={6}>
                                          <Grid item xs={12}>
                                            <Field name={`practiceElements.${index}.id`}>
                                              {({
                                                field: { name, value },
                                                form: { errors, setFieldValue, setFieldTouched },
                                                meta: { touched },
                                              }: FieldProps) => {
                                                return (
                                                <Autocomplete
                                                  key={`practiceId-${value}`}
                                                  options={filterDisabledPracticeOptions(practices.data)}
                                                  getOptionLabel={(t) => getPracticeElementTitle(t, !isEdit || !_isEmpty(values.formVersion))}
                                                  disabled={isEdit && !values.formVersion && !!values.practiceElements[index]?.id}
                                                  getOptionDisabled={(options) => {
                                                    if (options.disabledFlag) {
                                                      return true;
                                                    }
                                                  }}
                                                  PopperComponent={CustomPopperAutocomplete}
                                                  renderInput={params => (
                                                    <TextField
                                                      {...params}
                                                      label={`Practice Element ${isObservationService ? '': '*'}`}
                                                      variant="outlined"
                                                      error={touched && !!errors.practiceElements?.[index]?.id}
                                                      helperText={
                                                        touched && errors.practiceElements?.[index]?.id
                                                          ? errors.practiceElements?.[index]?.id
                                                          : ' '
                                                      }
                                                    />
                                                  )}
                                                  value={practices.data.find(p => p.practiceId === value)}
                                                  onChange={(_e, p: PracticeElementType) => {
                                                    hasFormChanged = true;
                                                    setFieldValue(`practiceElements.${index}.id`, p?.practiceId);
                                                    setFieldValue(
                                                      `practiceElements.${index}.needDescription`,
                                                      isPracticeTypeOther(p),
                                                    );
                                                    setFieldValue(`practiceElements.${index}.description`, !p?.otherFlag ? null : '' );
                                                  }}
                                                  filterOptions={filterOptionsMatchAllKeywords<PracticeElementType>(
                                                    (t) => getPracticeElementTitle(t, !isEdit || !_isEmpty(values.formVersion)),
                                                  )}
                                                  onBlur={() => setFieldTouched(name, true)}
                                                  disableClearable
                                                />
                                                );
                                              }}
                                            </Field>
                                          </Grid>
                                          {values.practiceElements?.[index]?.needDescription && (
                                          <Grid item xs={12} className={`${clsPrefix}-dynamic-group-briefDescription`}>
                                              <Field name={`practiceElements.${index}.description`}>
                                                {({ field, form, meta }: FieldProps) => {
                                                  return (
                                                    <TextField
                                                      label="Other Practice Element Description *"
                                                      variant="outlined"
                                                      error={meta.touched && !!meta.error}
                                                      helperText={meta.touched && meta.error ? meta.error : ' '}
                                                      fullWidth
                                                      onKeyDown={() => {
                                                        hasFormChanged = true;
                                                      }}
                                                      onBlur={() => form.setFieldTouched(field.name, true)}
                                                      inputProps={{
                                                        autoComplete: 'off',
                                                      }}
                                                      {...field}
                                                    />
                                                  );
                                                }}
                                              </Field>
                                          </Grid>
                                          )}
                                        </Grid>

                                        <Grid item container xs={12} md={6}>
                                          <Grid item xs={12}>
                                            <Field name={`concernTreatments.${index}.id`}>
                                              {({
                                                field: { name, value },
                                                form: { errors, setFieldValue, setFieldTouched, setFieldError, setErrors },
                                                meta: { touched },
                                              }: FieldProps) => {
                                                return (
                                                  <Autocomplete
                                                    key={`concernTreatmentId-${value}`}
                                                    options={filterDisabledConcernOptions(concernTreatments.data)}
                                                    getOptionLabel={getConcernTreatmentTitle}
                                                    getOptionDisabled={(options) => {
                                                      if (options.disabledFlag) {
                                                        return true;
                                                      }
                                                    }}
                                                    PopperComponent={CustomPopperAutocomplete}
                                                    renderInput={params => (
                                                      <TextField
                                                        {...params}
                                                        label={`Service Focus Area ${isObservationService ? '': '*'}`}
                                                        variant="outlined"
                                                        error={touched && !!errors.concernTreatments?.[index]?.id}
                                                        helperText={
                                                          touched && errors.concernTreatments?.[index]?.id
                                                            ? errors.concernTreatments?.[index]?.id
                                                            : ' '
                                                        }
                                                      />
                                                    )}
                                                    value={concernTreatments.data.find(t => t.concernTreatmentId === value)}
                                                    onChange={(_e, concern: ConcernTreatmentType) => {
                                                      hasFormChanged = true;
                                                      setFieldValue(`concernTreatments.${index}.id`, concern?.concernTreatmentId);
                                                      setFieldValue(
                                                        `concernTreatments.${index}.needDescription`,
                                                        isConcernTreatmentTypeOther(concern),
                                                      );
                                                      setFieldValue(`concernTreatments.${index}.description`, !concern?.otherFlag ? null : '' );
                                                    }}
                                                    filterOptions={filterOptionsMatchAllKeywords<ConcernTreatmentType>(
                                                      getConcernTreatmentTitle,
                                                    )}
                                                    disableClearable
                                                    onBlur={() => setFieldTouched(name, true)}
                                                  />
                                                );
                                              }}
                                            </Field>
                                          </Grid>
                                        {values.concernTreatments?.[index]?.needDescription && (
                                          <Grid item xs={12} className={`${clsPrefix}-dynamic-group-briefDescription`}>
                                              <Field name={`concernTreatments.${index}.description`}>
                                                {({ field, form, meta }: FieldProps) => {
                                                  return (
                                                    <TextField
                                                      label="Other Focus Area Description *"
                                                      variant="outlined"
                                                      error={meta.touched && !!meta.error}
                                                      helperText={meta.touched && meta.error ? meta.error : ' '}
                                                      fullWidth
                                                      onKeyDown={() => {
                                                        hasFormChanged = true;
                                                      }}
                                                      onBlur={() => form.setFieldTouched(field.name, true)}
                                                      inputProps={{
                                                        autoComplete: 'off',
                                                      }}
                                                      {...field}
                                                    />
                                                  );
                                                }}
                                              </Field>
                                          </Grid>
                                        )}
                                        </Grid>

                                        <Grid item container xs={12} md={6}>
                                          <Grid item xs={12}>
                                            <Field name={`focusAreaAsPlan.${index}.focusAreaAsPlan`}>
                                              {({
                                                field: { name, value },
                                                form: { errors, setFieldValue, setFieldTouched },
                                                meta: { touched, error },
                                              }: FieldProps) => {
                                                const getOptionTitle = (o: OptionSelectSLType) => o.name;
                                                return (
                                                  <Autocomplete
                                                    key={`focusAreaAsPlan-${value}`}
                                                    options={focusAreaAsPlan.data || []}
                                                    getOptionLabel={getOptionTitle}
                                                    PopperComponent={CustomPopperAutocomplete}
                                                    renderInput={params => (
                                                      <TextField
                                                        {...params}
                                                        label="Focus Area in Plan *"
                                                        variant="outlined"
                                                        error={touched && !!error}
                                                        helperText={touched && error ? error : ' '}
                                                      />
                                                    )}
                                                    value={focusAreaAsPlan.data.find(t => t.value === value)}
                                                    onChange={(_e, o: OptionSelectSLType) => {
                                                      hasFormChanged = true;
                                                      setFieldValue(name, o.value);
                                                    }}
                                                    filterOptions={filterOptionsMatchAllKeywords<OptionSelectSLType>(getOptionTitle)}
                                                    onBlur={() => setFieldTouched(name, true)}
                                                    disableClearable
                                                  />
                                                );
                                              }}
                                            </Field>
                                          </Grid>
                                        </Grid>

                                        {(!isCrisisOrConsult || values.outcomes[index]) && (
                                          <Grid item container xs={12} md={6}>
                                            <Grid item xs={12}>
                                              <Field name={`outcomes.${index}`}>
                                                {({
                                                  field: { name, value },
                                                  form: { errors, setFieldValue, setFieldTouched },
                                                  meta: { touched },
                                                }: FieldProps) => (
                                                  <Autocomplete
                                                    key={`outcomes-id-${value}`}
                                                    options={outcomes.data}
                                                    getOptionLabel={getOutcomeLabel}
                                                    PopperComponent={CustomPopperAutocomplete}
                                                    renderInput={params => (
                                                      <TextField
                                                        {...params}
                                                        label={`Outcome ${isObservationService ? '': '*'}`}
                                                        variant="outlined"
                                                        error={touched && !!errors.outcomes?.[index]}
                                                        helperText={
                                                          touched && errors.outcomes?.[index] ? errors.outcomes?.[index] : ' '
                                                        }
                                                      />
                                                    )}
                                                    value={outcomes.data.find(o => o.outcomeid === value)}
                                                    onChange={(_e, o: OutComeType) => {
                                                      hasFormChanged = true;
                                                      setFieldValue(`outcomes.${index}`, o?.outcomeid);
                                                    }}
                                                    filterOptions={filterOptionsMatchAllKeywords<OutComeType>(getOutcomeLabel)}
                                                    onBlur={() => setFieldTouched(name, true)}
                                                    disableClearable
                                                  />
                                                )}
                                              </Field>
                                            </Grid>
                                          </Grid> 
                                        )}
                                        {(!isCrisisOrConsult || values.notes[index]) && (
                                          <Grid item container xs={12} md={6}>
                                            <Grid item xs={12}>
                                              <Field name={`notes.${index}`}>
                                                {({ field, form, meta }: FieldProps) => (
                                                  <TextField
                                                    label="Session Note"
                                                    variant="outlined"
                                                    error={meta.touched && !!meta.error}
                                                    helperText="Brief justification summary of objectives addressed, activity completed, and justification for outcome selected."
                                                    fullWidth
                                                    onKeyDown={() => {
                                                      hasFormChanged = true;
                                                    }}
                                                    onBlur={() => form.setFieldTouched(field.name, true)}
                                                    multiline
                                                    minRows={1}
                                                    maxRows={3}
                                                    {...field}
                                                  />
                                                )}
                                              </Field>
                                            </Grid>
                                          </Grid>
                                        )}
                                      </Grid>
                                      {showDeleteButton && (
                                        <Grid item container xs={1} justifyContent="flex-end">
                                          <IconButton
                                            onClick={() => { 
                                              hasFormChanged = true;
                                              handleRemoveTarget(index);
                                              }
                                            }
                                            style={{
                                              transform: isMobile ? 'translateX(25px)' : 'translateX(0px)',
                                            }}>
                                            <FaTrash />
                                          </IconButton>
                                        </Grid>
                                      )}
                                    </Grid>
                                  ),
                                )}
                                <Grid container item spacing={3} className={`${clsPrefix}-the-rest`}>
                                  <Grid item xs={12}>
                                    <Button
                                      startIcon={<FaPlus />}
                                      variant="outlined"
                                      color="primary"
                                      onClick={handleAddTarget}
                                      disabled={values?.targets?.length === MAX_TARGETS}>
                                      Add Target
                                    </Button>
                                  </Grid>
                                </Grid>
                              </>
                            );
                          }}
                        </FieldArray>
                      )}
                    </FieldArray>
                  )}
                </FieldArray>
              )}
            </FieldArray>
          )}
        </FieldArray>
      )}
    </FieldArray>
  );
  const renderBimasFields = () => (
    <FieldArray name="progressMeasures">
      {({ push: pushProgress, remove: removeProgress }: ArrayHelpers) => (
            <FieldArray name="score">
              {
              
              ({ push: pushScore }: ArrayHelpers) => {
                    const showDeleteButton = values.progressMeasures?.length > 1;

                    const handleAddBimas = () => {
                      pushProgress( {measureCategoryCode: null, measureId: null, measureOther: null, score: '' });
                    };

                    const handleRemoveBimas = (index: number) => {
                      removeProgress(index);
                    };
                    return (
                      <>
                        {Array.from(
                            {
                              length: Math.max(values?.progressMeasures?.length || 1),
                            },
                          (_, index) => (
                            <Grid
                              key={`behavior-measure-${index}`}
                              container
                              item
                              spacing={3}
                              alignItems="flex-end"
                              className={`${clsPrefix}-dynamic-group`}>
                              <Grid container item xs={showDeleteButton ? 11 : 12} spacing={3}>
                              <Grid item xs={12} md={4} className={`${clsPrefix}-dynamic-group-briefDescription`}>
                                    <Field name={`progressMeasures.${index}.measureCategoryCode`}>
                                      {({
                                        field: { name, value },
                                        form: { errors, setFieldValue, setFieldTouched, setFieldError, setErrors },
                                        meta: { touched },
                                      }: FieldProps) => {
                                        return (
                                          <Autocomplete
                                            key={`measureCategoryCode-${value}`}
                                            options={filterDisabledPMOptions(progressMeasures.data)}
                                            getOptionLabel={getProgressMeasureTitle}
                                            getOptionDisabled={(options) => {
                                              if (options.deletedFlag) {
                                                return true;
                                              }
                                            }}
                                            PopperComponent={CustomPopperAutocomplete}
                                            renderInput={params => (
                                              <TextField
                                                {...params}
                                                label="Progress Measure"
                                                variant="outlined"
                                                error={touched && !!errors.progressMeasures?.[index]?.measureCategoryCode}
                                              />
                                            )}
                                            value={progressMeasures.data.find(t => t.measureCategoryCode === value)}
                                            onChange={(_e, progressMeasures: BehaviorCategoryType) => {
                                              hasFormChanged = true;
                                              setFieldValue(`progressMeasures.${index}.measureCategoryCode`, progressMeasures?.measureCategoryCode);
                                            }}
                                            filterOptions={filterOptionsMatchAllKeywords<BehaviorCategoryType>(
                                              getProgressMeasureTitle,
                                            )}
                                            onBlur={() => setFieldTouched(name, true)}
                                          />
                                        );
                                      }}
                                    </Field>
                                  </Grid>
                                {values.progressMeasures?.[index]?.measureCategoryCode === 'other' && (
                                  <Grid item xs={12} md={4} className={`${clsPrefix}-dynamic-group-briefDescription`}>
                                      <Field name={`progressMeasures.${index}.measureOther`}>
                                        {({ field, form, meta }: FieldProps) => {
                                          return (
                                            <TextField
                                              label="Measure Description"
                                              variant="outlined"
                                              error={meta.touched && !!meta.error}
                                              helperText={meta.touched && meta.error ? meta.error : ' '}
                                              fullWidth
                                              disabled={!values.progressMeasures?.[index]?.measureCategoryCode }
                                              onKeyDown={() => {
                                                hasFormChanged = true;
                                              }}
                                              onBlur={() => form.setFieldTouched(field.name, true)}
                                              inputProps={{
                                                autoComplete: 'off',
                                              }}
                                              {...field}
                                            />
                                          );
                                        }}
                                      </Field>
                                  </Grid>
                                )}
                                {values.progressMeasures?.[index]?.measureCategoryCode !== 'other' && (
                                  <Grid item xs={12} md={4} className={`${clsPrefix}-dynamic-group-briefDescription`}>
                                  <Field name={`progressMeasures.${index}.measureId`}>
                                    {({
                                      field: { name, value },
                                      form: { errors, setFieldValue, setFieldTouched },
                                      meta: { touched },
                                    }: FieldProps) => (
                                      <Autocomplete
                                        key={`measureId-${value}`}
                                        options={filterDisabledBMOptions(behaviorMeasures.data, values.progressMeasures?.[index]?.measureCategoryCode)}
                                        getOptionLabel={getBehaviorMeasureTitle}
                                        PopperComponent={CustomPopperAutocomplete}
                                        renderInput={params => (
                                          <TextField
                                            {...params}
                                            label="Measure Description"
                                            variant="outlined"
                                            error={touched && !!errors.progressMeasures?.[index].measureId}
                                            helperText={
                                              touched && errors.progressMeasures?.[index].measureId ? errors.progressMeasures?.[index].measureId : ' '
                                            }
                                          />
                                        )}
                                        value={behaviorMeasures.data.find(t => t.measureId === value)}
                                        onChange={(_e, progressMeasures: BehaviorMeasureType) => {
                                          hasFormChanged = true;
                                          setFieldValue(`progressMeasures.${index}.measureId`, progressMeasures?.measureId);
                                        }}
                                        onBlur={() => setFieldTouched(name, true)}
                                        disabled={!values.progressMeasures?.[index]?.measureCategoryCode}
                                        disableClearable
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                )}
                                  <Grid item xs={12} md={4} className={`${clsPrefix}-dynamic-group-briefDescription`}>
                                    <Field name={`progressMeasures.${index}.score`}>
                                      {({ field, form, meta }: FieldProps) => (
                                        <TextField
                                          label="Score"
                                          variant="outlined"
                                          error={meta.touched && !!meta.error}
                                          helperText={meta.touched && meta.error ? meta.error : ' '}
                                          fullWidth
                                          onKeyDown={() => {
                                            hasFormChanged = true;
                                          }}
                                          multiline
                                          minRows={1}
                                          maxRows={3}
                                          disabled={!values.progressMeasures?.[index]?.measureCategoryCode }
                                          {...field}
                                        />
                                      )}
                                    </Field>
                                  </Grid>
                              </Grid>
                              {showDeleteButton && (
                                <Grid item container xs={1} justifyContent="flex-end">
                                  <IconButton
                                    onClick={() => { 
                                      hasFormChanged = true;
                                      handleRemoveBimas(index);
                                      }
                                    }
                                    style={{
                                      transform: isMobile ? 'translateX(25px)' : 'translateX(0px)',
                                    }}>
                                    <FaTrash/>
                                  </IconButton>
                                </Grid>
                              )}
                            </Grid>
                          ),
                        )}
                        <Grid container item spacing={3} className={`${clsPrefix}-the-rest`}>
                          <Grid item xs={12}>
                            <Button
                              startIcon={<FaPlus />}
                              variant="outlined"
                              color="primary"
                              onClick={handleAddBimas}
                              disabled={values?.progressMeasures?.length === MAX_PROGRESS_MEASURES}>
                              Add Progress Measure
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    );
                  }}
                </FieldArray>
      )}
    </FieldArray>
  );
  return (
    <Form className={clsPrefix}>
      <MuiDatePickerMomentUtilsProvider>
        <Grid container spacing={1}>
          <Grid container item>
            <Grid item container xs={12} direction="row" justifyContent="flex-end" alignItems="center">
              <Field name="studentNotAvailable">
                {({ field, form }: FieldProps) => (
                  <>
                    <Checkbox
                      checked={values?.studentNotAvailable}
                      onChange={event => {
                        hasFormChanged = true;
                        form.setFieldValue(field.name, event.target.checked);
                        if (event.target.checked && form.values['primaryMethodOfContact'] === CONSTANT_ADD_LOG.WALKIN) {
                          form.setFieldValue('primaryMethodOfContact', '');
                        }
                      }}
                      color="primary"
                    />
                    <p>Student Not Available</p>
                  </>
                )}
              </Field>
            </Grid>
          </Grid>
          <Grid container item spacing={1} alignItems="flex-end">
            <Grid item xs={12} sm={values?.studentNotAvailable ? 6 : 3}>
              <Field name="provider">
                {(_fieldProps: FieldProps) => (
                  <TextField
                    label="Provider"
                    variant="outlined"
                    fullWidth
                    value={values?.provider ? values?.provider.firstName + ' ' + values?.provider.lastName : ''}
                    InputProps={{
                      readOnly: true,
                    }}
                    helperText={' '}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={values?.studentNotAvailable ? 6 : 3}>
              <Field name="credentialName">
                {(_fieldProps: FieldProps) => (
                  <TextField
                    label="Provider Credentials"
                    variant="outlined"
                    fullWidth
                    value={values?.provider ? values?.provider.credentialName : ''}
                    helperText={' '}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                )}
              </Field>
            </Grid>
            {isMobile && (
              <Grid item xs={12} sm={6}>
                <Field disabled={isEdit  || isClone || (localStorage.getItem(LOCAL_STORAGE_SUBMIT_CLONE) === 'true') ? false : true} name="sessionDate">
                  {(renderProps: FieldProps) => (
                    <MuiDatePickerFormikField
                      label="Session Date *"
                      disabled={isEdit  || isClone || (localStorage.getItem(LOCAL_STORAGE_SUBMIT_CLONE) === 'true') ? false : true}
                      minDate={ getStartValue(moment(startDate)) }
                      maxDate={ getSoonestDate(moment(endDate)) }
                      onChange={newDate => {
                        hasFormChanged = true;
                        renderProps.form.setFieldValue(renderProps.field.name, newDate);
                      }}
                      {...renderProps}
                    />
                  )}
                </Field>
              </Grid>
            )}
            {!values?.studentNotAvailable && (
              <Grid item xs={12} sm={6}>
                <Field name="primaryMethodOfContact">
                  {({
                    field: { name, value },
                    form: { errors, setFieldValue, setFieldTouched },
                    meta: { touched, error },
                  }: FieldProps) => {
                    const getOptionTitle = (o: sessionOptionsSelectSLType) => o.name;
                    return (
                      <Autocomplete
                        key={`primaryMethodOfContact`}
                        options={filterDisabledPMOCOptions(contact.data) || []}
                        getOptionLabel={getOptionTitle}
                        PopperComponent={CustomPopperAutocomplete}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Service Delivered *"
                            variant="outlined"
                            error={touched && !!error}
                            helperText={touched && error ? error : ' '}
                          />
                        )}
                        value={contact.data.find(t => t.value === value)}
                        onChange={(_e, o: sessionOptionsSelectSLType) => {
                          hasFormChanged = true;
                          setFieldValue(name, o.value);
                          // clear out group size, outcomes, notes, and BIMAS if serviceDelivered value changes to crisis or consultation
                          if(isCrisisOrConsultation(o.value, values.sessionType)){
                            setFieldValue('groupSize', null);
                            values.outcomes.forEach((_, index) => { values.outcomes[index] = null; });
                            values.notes.forEach((_, index) => { values.notes[index] = ''; });
                            values.progressMeasures.forEach((_, index) => {
                              if (values.progressMeasures?.[index]?.measureCategoryCode) values.progressMeasures[index].measureCategoryCode = null;
                              if (values.progressMeasures?.[index]?.measureId) values.progressMeasures[index].measureId = null;
                              if (values.progressMeasures?.[index]?.measureOther) values.progressMeasures[index].measureOther = '';
                              if (values.progressMeasures?.[index]?.score) values.progressMeasures[index].score = '';
                            });
                          }
                        }}
                        filterOptions={filterOptionsMatchAllKeywords<sessionOptionsSelectSLType>(getOptionTitle)}
                        onBlur={() => setFieldTouched(name, true)}
                        disableClearable
                      />
                    );
                  }}
                </Field>
              </Grid>
            )}
            <Grid item container spacing={1}>
              {!isMobile && (
                <Grid item xs={6} sm={3}>
                  <Field disabled={isEdit  || isClone || (localStorage.getItem(LOCAL_STORAGE_SUBMIT_CLONE) === 'true') ? false : true} name="sessionDate">
                    {(renderProps: FieldProps) => (
                      <MuiDatePickerFormikField
                        name="sessionDate"
                        label="Session Date *"
                        disabled={isEdit  || isClone || (localStorage.getItem(LOCAL_STORAGE_SUBMIT_CLONE) === 'true') ? false : true}
                        minDate={ getStartValue(moment(startDate)) }
                        maxDate={ getSoonestDate(moment(endDate)) }
                        onChange={newDate => {
                          hasFormChanged = true;
                          renderProps.form.setFieldValue(renderProps.field.name, newDate);
                        }}
                        {...renderProps}
                      />
                    )}
                  </Field>
                </Grid>
              )}
              
              {!values?.studentNotAvailable && (
                <Grid item xs={12} sm={3}>
                  <Field name="sessionType">
                  {({
                    field: { name, value },
                    form: { errors, setFieldValue, setFieldTouched },
                    meta: { touched, error },
                  }: FieldProps) => {
                    const getOptionTitle = (o: sessionOptionsSelectSLType) => o.name;
                    return (
                      <Autocomplete
                        key={`sessionType`}
                        options={filterDisabledPMOCOptions(sessionTypes.data) || []}
                        getOptionLabel={getOptionTitle}
                        PopperComponent={CustomPopperAutocomplete}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Session Type *"
                            variant="outlined"
                            error={touched && !!error}
                            helperText={touched && error ? error : ' '}
                          />
                        )}
                        value={sessionTypes.data.find(t => t.value === value)}
                        onChange={(_e, o: sessionOptionsSelectSLType) => {
                          hasFormChanged = true;
                          setFieldValue(name, o.value);
                          hasFormChanged = true;
                          setFieldValue(name, o.value);
                          // clear out group size, outcomes, notes, and BIMAS if serviceDelivered value changes to crisis or consultation
                          if(isCrisisOrConsultation(values.primaryMethodOfContact, o.value)){
                            setFieldValue('groupSize', null);
                            values.outcomes.forEach((_, index) => { values.outcomes[index] = null; });
                            values.notes.forEach((_, index) => { values.notes[index] = ''; });
                            values.progressMeasures.forEach((_, index) => {
                              if (values.progressMeasures?.[index]?.measureCategoryCode) values.progressMeasures[index].measureCategoryCode = null;
                              if (values.progressMeasures?.[index]?.measureId) values.progressMeasures[index].measureId = null;
                              if (values.progressMeasures?.[index]?.measureOther) values.progressMeasures[index].measureOther = '';
                              if (values.progressMeasures?.[index]?.score) values.progressMeasures[index].score = '';
                            });
                          } else if (o.value !== CONSTANT_ADD_LOG.WALKIN && values?.referredBy !== null) {
                            setFieldValue('referredBy', null);
                            setFieldValue('referredOtherDescription', '');
                          }


                        }}
                        filterOptions={filterOptionsMatchAllKeywords<sessionOptionsSelectSLType>(getOptionTitle)}
                        onBlur={() => setFieldTouched(name, true)}
                        disableClearable
                      />
                    );
                  }}
                </Field>
                </Grid>
              )}
         
              {values?.studentNotAvailable && (
                <Grid item xs={12} sm={6}>
                  <Field name="reasonStudentNotAvailable">
                    {({
                      field: { name, value },
                      form: { errors, setFieldValue, setFieldTouched },
                      meta: { touched, error },
                    }: FieldProps) => {
                      const getOptionTitle = (o: OptionSelectSLType) => o.name;
                      return (
                        <Autocomplete
                          key={`reasonStudentNotAvailable`}
                          options={filterDisabledOptionSelectSL(absentReason.data) || []}
                          getOptionLabel={getOptionTitle}
                          PopperComponent={CustomPopperAutocomplete}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Reason Student Not Available *"
                              variant="outlined"
                              error={touched && !!error}
                              helperText={touched && error ? error : ' '}
                            />
                          )}
                          value={absentReason.data.find(t => t.value === value)}
                          onChange={(_e, o: OptionSelectSLType) => {
                            hasFormChanged = true;
                            setFieldValue(name, o.value);
                          }}
                          filterOptions={filterOptionsMatchAllKeywords<OptionSelectSLType>(getOptionTitle)}
                          onBlur={() => setFieldTouched(name, true)}
                          disableClearable
                        />
                      );
                    }}
                  </Field>
                </Grid>
              )}
              {isContactWalkin && !values?.studentNotAvailable && (
                <>
                  <Grid item xs={12} sm={isReferredByOther ? 3 : 6}>
                    <Field name="referredBy">
                      {({
                        field: { name, value },
                        form: { errors, setFieldValue, setFieldTouched },
                        meta: { touched, error },
                      }: FieldProps) => {
                        const getOptionTitle = (o: OptionSelectSLType) => o.name;
                        return (
                          <Autocomplete
                            key={`referredBy`}
                            options={referredBy.data || []}
                            getOptionLabel={getOptionTitle}
                            PopperComponent={CustomPopperAutocomplete}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Initiated by *"
                                variant="outlined"
                                error={touched && !!error}
                                helperText={touched && error ? error : ' '}
                              />
                            )}
                            value={referredBy.data.find(t => t.value === value)}
                            onChange={(_e, o: OptionSelectSLType) => {
                              hasFormChanged = true;
                              setFieldValue(name, o.value);
                              if (!isOtherInitiators(o.value) && !isEmpty(values?.referredOtherDescription)) {
                                setFieldValue('referredOtherDescription', '');
                              }
                            }}
                            filterOptions={filterOptionsMatchAllKeywords<OptionSelectSLType>(getOptionTitle)}
                            onBlur={() => setFieldTouched(name, true)}
                            disableClearable
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  {isReferredByOther && !values?.studentNotAvailable && (
                    <Grid item xs={12} sm={3}>
                      <Field name="referredOtherDescription">
                        {({ field, meta, form }: FieldProps) => (
                          <TextField
                            label="Initiator Name *"
                            variant="outlined"
                            error={meta.touched && meta.error ? true : false}
                            helperText={meta.touched && meta.error ? meta.error : ' '}
                            fullWidth
                            onKeyDown={() => {
                              hasFormChanged = true;
                            }}
                            onBlur={() => form.setFieldTouched(field.name, true)}
                            {...field}
                          />
                        )}
                      </Field>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
            {!values?.studentNotAvailable && (
              <Grid item xs={12} sm={3}>
                <Field name="startTime">
                  {(props: FieldProps) => (
                    <MuiTimePickerFormikField
                      ampm={true}
                      label="Start time *"
                      {...props}
                      onChange={(time: Moment) => {
                        hasFormChanged = true;
                        props.form.setFieldValue(props.field.name, time);
                        if (values.endTime) {
                          const start = moment(time).seconds(0).milliseconds(0);
                          const end = moment(values.endTime).seconds(0).milliseconds(0);
                          const diffTime = moment(start).diff(moment(end));
                          const diffTimeinMin = moment.duration(diffTime).asMinutes();
                          //const duration = Math.floor(moment.duration(diffTimeInMs).asMinutes());
                          props.form.setFieldValue('sessionLength', Math.abs(diffTimeinMin));
                        }
                      }}
                    />
                  )}
                </Field>
              </Grid>
            )}
            {!values?.studentNotAvailable && (
              <Grid item xs={12} sm={3}>
                <Field name="endTime">
                  {(props: FieldProps) => (
                    <MuiTimePickerFormikField
                      {...props}
                      ampm={true}
                      label="End time *"
                      onChange={(time: Moment) => {
                        hasFormChanged = true;
                        //props.form.setFieldTouched('endTime', true);
                        props.form.setFieldValue(props.field.name, time);
                        if (values.startTime) {
                          const end = moment(time).seconds(0).milliseconds(0);
                          const start = moment(values.startTime).seconds(0).milliseconds(0);
                          const diffTime = moment(end).diff(moment(start));
                          const diffTimeinMin = moment.duration(diffTime).asMinutes();
                          //const duration = Math.floor(moment.duration(diffTimeInMs).asMinutes());
                          props.form.setFieldValue('sessionLength', Math.abs(diffTimeinMin));
                        }
                      }}
                    />
                  )}
                </Field>
              </Grid>
            )}
            {!values?.studentNotAvailable && (
              <Grid item xs={12} sm={(isCrisisOrConsult && !values?.groupSize) ? 6 : 3}>
                <Field name="sessionLength">
                  {({ field, form, meta }: FieldProps) => {
                    return (
                      <TextField
                        {...field}
                        label="Session Length (mins) *"
                        variant="outlined"
                        fullWidth
                        type="number"
                        InputLabelProps={{ shrink: field.value || field.value === 0 ? true : false }}
                        error={meta.touched && !!meta.error}
                        helperText={meta.touched && meta.error ? meta.error : ' '}
                        onChange={e => {
                          hasFormChanged = true;
                          let input = e.target.value;
                          form.setFieldValue(field.name, input.replace(/[^0-9]/g, ''));
                        }}
                        onBlur={() => {
                          if (values.startTime) {
                            let newEndTime = moment(values.startTime)
                              .add(values.sessionLength, 'minutes')
                              .toISOString();
                            form.setFieldValue('endTime', newEndTime);
                          }/* else if (!values.startTime && values.endTime) {
                            let newStartTime = moment(values.endTime)
                              .subtract(values.sessionLength, 'minutes')
                              .toISOString();
                            form.setFieldValue('startTime', newStartTime);
                          } */
                          form.setFieldTouched(field.name, true);
                        }}
                      />
                    );
                  }}
                </Field>
              </Grid>
            )}
            {!values?.studentNotAvailable && (!isCrisisOrConsult || values.groupSize) && (
              <Grid item xs={12} sm={3}>
                <Field name="groupSize">
                  {({ field, form, meta }: FieldProps) => (
                    <TextField
                      {...field}
                      label="Group Size"
                      variant="outlined"
                      error={values.groupSize === '1' && isEdit ? true : (meta.touched && !!meta.error)}
                      helperText={meta.touched && meta.error ? meta.error : values.groupSize === '1' && isEdit ? groupSizeError : ' '}
                      fullWidth
                      InputProps={{
                        inputProps: {
                          min: 2,
                        },
                      }}
                      type="number"
                      onBlur={() => form.setFieldTouched(field.name, true)}
                      onChange={e => {
                        hasFormChanged = true;
                        let input = e.target.value;
                        form.setFieldValue(field.name, input.replace(/[^0-9]/g, ''));
                      }}
                    />
                  )}
                </Field>
              </Grid>
            )}
            {!values?.studentNotAvailable && (
              <Grid item xs={12} sm={6}>
                <Field name="location">
                  {({ field, meta, form }: FieldProps) => (
                    <SearchDropdownBox<ServiceLocation>
                      placeholder="Location *"
                      value={field.value}
                      onChange={v => {
                        hasFormChanged = true;
                        form.setFieldValue('location', v);
                      }}
                      loading={searchLocations.loading}
                      options={searchLocations.data || []}
                      onSearch={searchSLLocation}
                      getOptionLabel={getLocationOptionLabel}
                      onClear={clearSearchLocation}
                      noOptionsText={"No locations found"}
                      errorMessage={
                        meta.touched && form.errors.location?.toString() ? form.errors.location?.toString() : ' '
                      }
                      onBlur={() => {
                        form.setFieldTouched(field.name, true);
                      }}
                    />
                  )}
                </Field>
              </Grid>
            )}
            {!values?.studentNotAvailable && (
              <Grid item xs={12} sm={6}>
                <Field name="locationDetails">
                  {({ field, form, meta }: FieldProps) => (
                    <TextField
                      {...field}
                      label="Location Details"
                      variant="outlined"
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched && meta.error ? meta.error : ' '}
                      fullWidth
                      onKeyDown={() => {
                        hasFormChanged = true;
                        form.setFieldTouched(field.name, true)
                      }}
                    />
                  )}
                </Field>
              </Grid>
            )}
            {!values?.studentNotAvailable && (
              <Grid item xs={12} sm={6}>
                <Field name="deliveryMode">
                  {({
                    field: { name, value },
                    form: { errors, setFieldValue, setFieldTouched },
                    meta: { touched, error },
                  }: FieldProps) => {
                    const getOptionTitle = (o: OptionSelectSLType) => o.name;
                    return (
                      <Autocomplete
                        key={`deliveryMode`}
                        options={deliveryMode.data || []}
                        getOptionLabel={getOptionTitle}
                        PopperComponent={CustomPopperAutocomplete}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Delivery Mode *"
                            variant="outlined"
                            error={touched && !!error}
                            helperText={touched && error ? error : ' '}
                          />
                        )}
                        value={deliveryMode.data.find(t => t.value === value)}
                        onChange={(_e, o: OptionSelectSLType) => {
                          hasFormChanged = true;
                          setFieldValue(name, o.value);
                          o.value !== CONSTANT_ADD_LOG.IN_PERSON &&
                            values.roomName !== '' &&
                            setFieldValue('roomName', '');
                        }}
                        filterOptions={filterOptionsMatchAllKeywords<OptionSelectSLType>(getOptionTitle)}
                        onBlur={() => setFieldTouched(name, true)}
                        disableClearable
                      />
                    );
                  }}
                </Field>
              </Grid>
            )}
            {isDeliveryInPerson && !values?.studentNotAvailable && (
              <Grid item xs={12} sm={6}>
                <Field name="roomName">
                  {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                    <TextField
                      {...field}
                      label="Classroom Number"
                      variant="outlined"
                      fullWidth
                      onKeyDown={() => {
                        hasFormChanged = true;
                      }}
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched && meta.error ? meta.error : ' '}
                    />
                  )}
                </Field>
              </Grid>
            )}
          </Grid>
          {!values?.studentNotAvailable && renderDynamicFields()}
          {!values?.studentNotAvailable && !hideProgressMeasures && renderBimasFields()}
          <Grid container item spacing={1} className={`${clsPrefix}-the-rest`}>
            <Grid item xs={12} md={12}>
              <Field name="comments">
                {({ field, form }: FieldProps) => (
                  <>
                    <TextField
                      label="Follow-up and/or Other"
                      helperText="Anticipated next steps (i.e., follow-up session plan/consultation, support changes, homework, etc.). Concise statement re: other relevant information as applicable."
                      variant="outlined"
                      fullWidth
                      multiline
                      onKeyDown={() => {
                        hasFormChanged = true;
                      }}
                      minRows={3}
                      maxRows={5}
                      {...field}
                    />
                  </>
                )}
              </Field>
            </Grid>
          { ((!isEdit && !providerChecked) || (isEdit && !providerChecked)) && (
            <Grid item xs={12} md={12}>
              <Field name={`licenseAttestUserId`}>
                {({ field: { name, value },
                    form: { errors, setFieldValue },
                    meta: { touched }, }: FieldProps) => (
                      <>
                    <FormControlLabel
                    value={values.licenseAttestUserId ? true : false}
                    onChange={v => {
                      providerChecked = true;
                      setFieldValue(name, parseInt(localStorage.getItem('userId')));
                      setFieldValue('licenseAttestDate', (moment().utcOffset(-600).format('YYYY-MM-DDTHH:mm:ss')));
                    }}
                    control={<Checkbox />}
                    disabled={providerChecked}
                    label={"Provider Signature *"}
                    labelPlacement="end"
                    />
                    <FormHelperText className={`${clsPrefix}-form-helper-text`}>{providerSigError ? 'Provider Signature is Required' : ''}</FormHelperText>
                    </>
                )}
              </Field>
            </Grid> )}

            { (providerChecked) && (
            <Grid item xs={12} md={12}>
              <Field name={'licenseAttestUserId'}>
              {({ field: { name, value },
                  form: { errors, setFieldValue },
                  meta: { touched }, }: FieldProps) => (
                  <>
                    <FormControlLabel
                    value={values.licenseAttestUserId ? true : false}
                    onChange={v => {
                      providerChecked = true;
                      setFieldValue(name, parseInt(localStorage.getItem('userId')));
                      setFieldValue('licenseAttestDate', (moment().utcOffset(-600).format('YYYY-MM-DDTHH:mm:ss')));
                    }}
                    disabled={providerChecked}
                    control={<Checkbox defaultChecked />}
                    label={"Provider Signature *"}
                    labelPlacement="end"
                    />
                    <FormHelperText className={`${clsPrefix}-form-helper-text`}>{providerSigError ? 'Provider Signature is Required' : ''}</FormHelperText>
                  </>
                )}
              </Field>
              { (!isEdit) && (
              <Typography variant="body2" display="block" gutterBottom>
                {(localStorage.getItem('userFullName') + ' ' + getCurrentDate()) }
              </Typography>)}
              { (isEdit && !licenseAttestFlag) && (
              <Typography variant="body2" display="block" gutterBottom>
                { (localStorage.getItem('userFullName') + ' ' + getCurrentDate()) }
              </Typography>)}
              { (isEdit && licenseAttestFlag) && (
              <Typography variant="body2" display="block" gutterBottom>
                {(editLicenseAttestFullName + ' ' + formatDate(editLicenseAttestDate)) }
              </Typography>)}
            </Grid> )}
          </Grid>
        </Grid>
      </MuiDatePickerMomentUtilsProvider>
    </Form>
  );
};

const AddSessionLogFormik: React.FC<FormikConfig<AddSessionLogType> & ReturnType<typeof mapState>> = ({
  children,
  initialValues,
  validationSchema,
  ...formProps
}) => {

  return (
    <Formik<AddSessionLogType> validationSchema={validationSchema} initialValues={initialValues} {...formProps}>
      {children}
    </Formik>
  );
};

const mapState = (state: IRootState) => {
  const { provider, sessionDate } = state.sessionLogs.addSession;
  const { mode, licenseAttestFlag } = state.sessionLogs.searchLogs.selectedLog;
  const { searchLocations, targets, concernTreatments, focusAreaAsPlan, practices, outcomes, behaviorMeasures, progressMeasures, contact, sessionTypes, deliveryMode, directNonDirect, referredBy, absentReason } =
    state.sessionLogs.addSession.search;
  return {
    provider,
    sessionDate,
    searchLocations,
    targets,
    concernTreatments,
    focusAreaAsPlan,
    practices,
    outcomes,
    behaviorMeasures,
    progressMeasures,
    contact,
    sessionTypes,
    deliveryMode,
    directNonDirect,
    referredBy,
    absentReason,
    mode,
    licenseAttestFlag,
  };
};

const mapDispatch = {
  searchSLLocation: searchLocationAddSLAsync.request,
  clearSearchLocation: searchLocationAddSLAsync.cancel,
  setLicenseAttestFlagSL: setLicenseAttestFlagSL,
};

export const ConnectedAddSessionLogForm = connect(mapState, mapDispatch)(AddSessionLogForm);

export default React.memo(connect(mapState)(AddSessionLogFormik));
