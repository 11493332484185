import React from 'react';
import PageContentContainer from 'src/components/PageContentContainer';
import StickyPageHeader from 'src/components/StickyPageHeader';
import './styles.scss';
/* eslint-disable */
const Version: React.VoidFunctionComponent = ({
}) => {

  return (
    <PageContentContainer>
      <StickyPageHeader>
        <br/>
        <h3>Branch: demo/1.8.0-RC3</h3><br />
        <h3>Commit: 611b2029</h3><br />
        <h3>Build Date: 2024-12-20T05:40:39Z</h3>
      </StickyPageHeader>
    </PageContentContainer>
  );
};

export default (Version);